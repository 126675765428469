import React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { SxProps, Theme, lighten } from "@mui/material/styles";
import { useTheme } from "@cbex/utils/theme";

export type CustomTextFieldProps = TextFieldProps & {
  controldisabled?: boolean;
  useFullWidth?: boolean;
  size?: string;
  sx?: SxProps<Theme>;
};

export const CustomTextField = ({
  size = "small",
  useFullWidth = true,
  controldisabled,
  variant = "filled",
  value,
  sx,
  ...props
}: CustomTextFieldProps) => {
  const theme = useTheme();
  return (
    <TextField
      inputProps={{ readOnly: controldisabled }}
      fullWidth={useFullWidth ? true : false}
      disabled={controldisabled}
      value={value || ""}
      size={size}
      variant={variant}
      error={props.error}
      required={props.required}
      {...props}
      sx={[
        {
          "&.MuiTextField-root .Mui-disabled ": {
            backgroundColor: theme.appColors.disabledBackgroundColor,
            color: lighten(theme.appColors.fontColourDarkGrey, 0.6),
            opacity: 0.8,
            pointerEvents: "unset",
            "&&:hover": {
              cursor: "default",
            },
          },
          "& .MuiInputBase-input.Mui-disabled": {
            color: lighten(theme.appColors.fontColourDarkGrey, 0.6),
            WebkitTextFillColor: lighten(
              theme.appColors.fontColourDarkGrey,
              0.6
            ),
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
};

export default CustomTextField;
