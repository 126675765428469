import React from "react";
import Autocomplete, {
  AutocompleteProps,
  autocompleteClasses,
} from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import { useTheme } from "@cbex/utils/theme";
import Box from "@mui/material/Box";

export const CustomAutoComplete = <T,>(
  params: AutocompleteProps<
    T,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  >
) => {
  const theme = useTheme();
  return (
    <Autocomplete
      id="CustomAutoCompleteComponent"
      renderOption={(props, option, state, ownerState) => (
        <Box
          sx={{
            borderRadius: "8px",
            margin: "5px",
            [`&.${autocompleteClasses.option}`]: {
              padding: "8px",
            },
          }}
          component="li"
          {...props}
        >
          {ownerState.getOptionLabel?.(option)}
        </Box>
      )}
      PaperComponent={(props) => (
        <Paper
          variant="elevation"
          sx={{
            backgroundColor: theme.appColors.contentBackground,
            fontSize: "25px",
          }}
          {...props}
        />
      )}
      forcePopupIcon={params.disabled ? false : true}
      {...params}
    />
  );
};

export default CustomAutoComplete;
